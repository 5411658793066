$(function(){

  //===============================================
  /* トップメインスライド */
  //===============================================
  $('#slide').slick({
    autoplay:true,
    dots:false,
    fade:true,
    arrows:false,
    autoplaySpeed: 4000,
    speed: 5000,
  });
});